import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { toggleSetting } from '../../store/actions/actions';
import vistifyLogo from '../../images/vistify-logo-header.png';
import HeaderSearch from './HeaderSearch';

import { Link } from 'react-router-dom';
import AuthService from 'services/authentication.service';
import Now from 'components/Common/Now';
import { Col } from 'reactstrap';

type HeaderProps = {
    toggleSetting: typeof toggleSetting
};

class Header extends Component<HeaderProps> {

    state = {
        navSearchOpen: false,
        itemPerPage: 5,
        isLoading: true,
        notifications: []
    };

    toggleUserblock = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.toggleSetting('asideToggled');
    }

    resize() {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        document.dispatchEvent(event);
    }

    toggleNavSearch: React.MouseEventHandler = e => {
        e.preventDefault();
        this.setState({
            navSearchOpen: !this.state.navSearchOpen
        });
    };

    closeNavSearch: React.EventHandler<any> = e => {
        e.preventDefault();
        this.setState({
            navSearchOpen: false
        });
    };

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src={vistifyLogo} alt="App Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}
                    <Col xl={4} md={12}>
                      <ul className="navbar-nav flex-row">
                        { /* START date card */}
                        <Now format="dddd" className="text-m" />,
                        &nbsp;
                        <Now format="MMMM" className="text-m" />
                        &nbsp;
                        <Now format="D" className="text-m" />,
                        &nbsp;
                        <Now format="h:mm" className="text-m" />
                        <Now format="a" className="text-m" />
                      { /* END date card */}
                      </ul>
                    </Col>
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        <li className="nav-item">
                            <Link className="nav-link"
                                to={{
                                    pathname: '/account-details',
                                }}>
                                <em className="icon-user nav-icon"></em>
                                <span className="nav-icon-name">Account</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" onClick={AuthService.logout}>
                                <em className="icon-login nav-icon"></em>
                                <span className="nav-icon-name">Log Out</span>
                            </a>
                        </li>
                    </ul>
                    { /* END Right Navbar */}

                    {/* START Search form */}
                    <HeaderSearch isOpen={this.state.navSearchOpen} onClose={this.closeNavSearch} />
                    {/* END Search form */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}


const mapDispatchToProps = (dispatch: Dispatch) => ({ toggleSetting: bindActionCreators(toggleSetting, dispatch) })

export default connect(
    null,
    mapDispatchToProps
)(Header);
