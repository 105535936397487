import { IUserGroupType } from "./group.types";

export interface IUserAuthInputType {
  email: string,
  password: string
}

export interface IUserInputType {
  id?: number,
  emailAddress: string,
  firstName: string,
  lastName: string,
  role: 'AGENT' | 'ADMIN' | 'CONTROLLER',
  isActive: boolean,
  firebaseId?: string,
  addedUserGroups?: string[],
  deletedUserGroups?: string[]
}

export interface IFirebaseTokenClaimsType {
  [p: string]: any
}

export interface IFirebaseUserType {
  localId?: string,
  emailAddress?: string,
  emailVerified?: boolean,
}

export interface IUserType {
  id: number
  firebaseId: string,
  firstName: string,
  lastName: string
  emailAddress: string,
  emailVerified?: boolean,
  role: Role,
  isActive: boolean,
  dateCreated: Date,
  userGroups: IUserGroupType[]
}

export interface IEmailAvailabilityType {
  isEmailAvailable: {
    isAvailable: boolean
  }
}

export interface IEditUserResponseType {
  users: {
    update: {
      id?: number
    }
  }
}

export interface IPasswordInputType {
  id?: number,
  password: string,
  confirmPassword: string,
}

export interface IPasswordType {
  password: string,
  confirmPassword: string,
}

export enum Role {
  AGENT = 'AGENT',
  CONTROLLER = 'CONTROLLER',
  ADMIN = 'ADMIN' 
}
